import { useState } from 'react';

import { styled, alpha } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomMenu({ menuOptions }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const [clickedMenuItem, setClickedMenuItem] = useState('');

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    function timeout(delay) {
        return new Promise(res => setTimeout(res, delay));
    }

    const handleClose = async () => {
        await timeout(1000);
        setAnchorEl(null);
        setClickedMenuItem('')
    };

    return (
        <div>
            <IconButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                sx={{
                    alignSelf: 'center',
                    p: 0,
                }}
            >
                <KeyboardArrowDownIcon />
            </IconButton>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {
                    menuOptions?.map((option, key) => (
                        option &&
                        <MenuItem key={key} onClick={() => { option.onClick(); setClickedMenuItem(option.key); handleClose(); }} disableRipple>
                            {option.icon}
                            {clickedMenuItem === 'share' && clickedMenuItem === option.key ? 'Link copied!' : option.title}
                        </MenuItem>
                    ))
                }
            </StyledMenu>
        </div >
    );
}