import { createSlice } from "@reduxjs/toolkit"

const initialState = { page: 0, filters: [] }

const pageSlice = createSlice({
    name: 'pageNum',
    initialState: initialState,
    reducers: {
        setFilteredPage: (state, action) => {
            const { page, filters } = action.payload
            state.page = page
            state.filters = filters
        },
        resetPage: () => initialState,
    },
})


export const { setFilteredPage, resetPage } = pageSlice.actions

export default pageSlice.reducer

export const selectPage = (state) => state.pageNum.page
export const selectFilters = (state) => state.pageNum.filters