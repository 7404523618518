import { Grid, Box, Typography, IconButton } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import TripCard from './TripCard'
import TripCardSkeleton from './TripCardSkeleton'

import { useGetTripQuery } from "./tripApiSlice"

import { useParams, Outlet, useNavigate } from 'react-router-dom';

import { useDeleteTripMutation } from './tripApiSlice'

import { useSelector } from "react-redux"
import { selectCurrentUserId } from '../auth/authSlice'


export default function SingleTrip() {

    const { id: tripId } = useParams()

    const currentUserId = useSelector(selectCurrentUserId)

    const navigate = useNavigate()

    const {
        data: trip,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetTripQuery({ tripId }, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [deleteTrip, { isLoading: isDeleteLoading, isError: isDeleteError, error: deleteError }] = useDeleteTripMutation()

    const handleDeleteClicked = async () => {
        await deleteTrip({ tripId }).unwrap()
        navigate(-1)
    }

    const deleteBtn = (
        <IconButton
            variant="contained"
            onClick={handleDeleteClicked}
        >
            <DeleteOutlineIcon />
        </IconButton>
    )

    let content

    if (isSuccess) {
        const isAccountOwner = currentUserId === trip.userId
        content = (<TripCard trip={trip} options={isAccountOwner && deleteBtn} />)
    }

    if (isLoading) {
        content = (<TripCardSkeleton />)
    }

    if (isError) {
        content = (<Typography p={3}>{error.data?.message || error + ' :/'}</Typography>)
    }

    return (
        <Grid container>
            <Box
                sx={{ width: '100%', height: '100%' }}
                display="flex"
                justifyContent="center"
            >
                <Grid
                    item
                    xs={11}
                    md={5}
                    pt={5}
                >
                    <Box>
                        {content}
                    </Box>
                </Grid>
            </Box>
            <Outlet />
        </Grid>
    )
}
