
import { Grid, IconButton, Stack } from '@mui/material';

import Skeleton from '@mui/material/Skeleton';


export default function ProfileDataSkeleton() {
    return (
        <Grid container direction='row' justifyContent='space-between' alignItems='center' mb={3}>
            <Stack direction='row' alignItems='center'>
                <IconButton onClick={() => { }} sx={{ p: 0, mr: '2vh' }}>
                    <Skeleton animation="wave" variant="circular" width='12vh' height='12vh' />
                </IconButton>
                <Skeleton animation="wave" height={10} width="100%" />
            </Stack>
            <Skeleton variant="rectangular" width='7vh' height='5vh' />
        </Grid>
    )
}
