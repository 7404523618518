import { createSlice } from "@reduxjs/toolkit"

const initialState = { page: 0 }

const userPageSlice = createSlice({
    name: 'userPageNum',
    initialState: initialState,
    reducers: {
        setPage: (state, action) => {
            const { page } = action.payload
            state.page = page
        },
        resetPage: () => initialState,
    },
})


export const { setPage, resetPage } = userPageSlice.actions

export default userPageSlice.reducer

export const selectPage = (state) => state.userPageNum.page