import { styled } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';


export const UploadButton = ({ title, onChange, multiple, subtitle }) => {
    return (
        <Button sx={{ justifyContent: 'flex-start', width: '100%', pt: 2, pb: 2 }} component='label' variant='outlined' startIcon={<PhotoLibraryIcon />}>
            <Typography variant='p'>
                {title}
            </Typography>
            <Typography component='span' ml={1} sx={{ fontSize: 9 }}>
                {subtitle}
            </Typography>
            <VisuallyHiddenInput type="file" multiple={multiple} onChange={onChange} />
        </Button>
    );
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    padding: 0,
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 0,
});
