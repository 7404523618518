import { CustomModal } from './CustomModal'

import { useParams } from "react-router-dom"

import { generateAwsS3Url } from '../config/utils'


export default function ImgFullScreen() {

    const { imgId: key } = useParams()

    const content = (
        <img style={{ maxWidth: '80vh', maxHeight: '70vh' }} alt={key} src={generateAwsS3Url('trip_images/' + key)}></img>
    )
    return (
        <CustomModal Content={content} customStyle={{ maxWidth: '100vh', maxHeight: '90vh' }} />
    )
}
