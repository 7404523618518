import { useState } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import SearchIcon from '@mui/icons-material/Search';
import { Stack, Typography, Divider, Autocomplete, TextField } from '@mui/material';

import { useSearchTripsQuery } from './tripApiSlice'
import { wrapWithLink } from '../../config/utils'


const renderItem = (props, option, inputValue) => {
    const isUserOrTrip = option.isUser ? 'user' : 'trip'
    const isUser = isUserOrTrip === 'user'

    //name here is trip name...
    const matchBy = isUser ? option.username : option.name

    const matches = match(matchBy, inputValue, { insideWords: true });
    const parts = parse(matchBy, matches);

    return (
        <>
            <li {...props}>
                <Stack>
                    <Typography variant="body1" color="initial">
                        {parts.map((part, index) => (
                            <label
                                variant="body1" color="initial"
                                key={index}
                                style={{
                                    fontWeight: part.highlight ? 700 : 400,
                                }}
                            >
                                {part.text}
                            </label>
                        ))}
                    </Typography>
                    <Typography variant="body2" color="initial">{isUser ? 'User' : 'Trip'}</Typography>
                </Stack>
            </li>
            <Divider variant='middle' />
        </>

    );
}


export function Search() {

    const [keyword, setKeyword] = useState('')

    const {
        data: searchResults,
        isLoading,
        isSuccess,
        isError,
        error
    } = useSearchTripsQuery({ keyword }, {
        pollingInterval: 60000 * 5, // Automatically refresh the trips on home page every 3 minutes.
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const handleInput = (e) => setKeyword(e.target.value.toLowerCase())

    return (
        <Autocomplete
            id="highlights-demo"
            sx={{ width: '25%', mr: '2%', "& .MuiAutocomplete-popupIndicator": { transform: "none" } }}
            options={searchResults ?? []}
            noOptionsText={keyword ? 'No results found' : 'Please Type something'}
            popupIcon={<SearchIcon />}
            size='small'
            componentsProps={{ popper: { style: { width: '40%' } } }}
            loading={isLoading}
            getOptionLabel={(option) => option.username}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{ border: 1, borderColor: 'primary.light', borderRadius: '3%' }}
                    onChange={handleInput}
                    placeholder="Search Triplio" />
            )}
            renderOption={(props, option, { inputValue }) => {
                return wrapWithLink(
                    renderItem(props, option, inputValue),
                    option.isUser ? '/profile/' : '/trip/',
                    option._id
                )
            }}
        />
    );
}