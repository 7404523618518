import { Grid, Typography, Paper, Avatar, Box, Chip } from '@mui/material';

import { ImageListCustom } from '../../components/ImageListCustom'
import { SmartTypography } from '../../components/SmartTypography'
import { wrapWithLink } from '../../config/utils'
import CATEGORIES from '../../config/categories'

import dayjs from 'dayjs'


export default function TripCard({ trip, options }) {
    const { name, description, location, date: tripDate, createdAt, username: createdBy, images, userId, category: categoryValue } = trip

    const createdAtReadable = dayjs(createdAt).format('YYYY-MM-DD HH:mm')

    const category = Object.keys(CATEGORIES).find(key => CATEGORIES[key] == categoryValue)

    return (
        <Paper elevation={1} style={{ background: '#fff' }} sx={{ borderRadius: 3, margin: 2, position: 'relative' }}>
            <Box sx={{ position: 'absolute', right: 10, top: 10 }}> {options} </Box>
            <Grid container alignItems="center" p={2}>
                <Grid container pb={2}>
                    <Grid item mr={2} display='flex' alignItems='center'>
                        {wrapWithLink(<Avatar />, '/profile/', userId)}
                    </Grid>
                    <Grid item >
                        <Typography variant='h6'>{name}</Typography>
                        <Typography component='p'>
                            {wrapWithLink(createdBy + ' - ' + createdAtReadable, '/profile/', userId)}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography component='p'>{location} - {tripDate}</Typography>
                </Grid>

                <Grid item xs={12} mt={1} mb={1}>
                    <Chip
                        variant='outlined'
                        size='small'
                        label={category}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SmartTypography text={description} length={500} />
                </Grid>
                <Grid container>
                    {!!images?.length && <ImageListCustom cols={4} imageList={images} />}
                </Grid>
            </Grid>
        </Paper>
    )
}
