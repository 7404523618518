import { Grid, Typography } from '@mui/material';

import CustomFab from './CustomFab'
import TripsList from './TripsList'
import TripCardSkeleton from '../features/trips/TripCardSkeleton'
import Fitlers from '../features/trips/Fitlers'

import { useNavigate, Outlet } from 'react-router-dom'

import { useGetTripsQuery } from '../features/trips/tripApiSlice'
import { useSelector, useDispatch } from 'react-redux'
import { selectPage, setFilteredPage, selectFilters } from '../features/trips/pageSlice'


const Home = () => {

    const page = useSelector(selectPage)
    const filters = useSelector(selectFilters)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetTripsQuery({ page, filters }, {
        pollingInterval: 60000 * 5, // Automatically refresh the trips on home page every 3 minutes.
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const handleCreateTrip = () => {
        navigate(`/createTrip`)
    }

    let content

    if (isLoading) {
        content = (
            <Grid item>
                {Array(8).fill().map((_, key) => <TripCardSkeleton key={key} />)}
            </Grid>
        )
    }

    if (isError) {
        console.log('error', error)
        content = (<Typography p={3}>{error.data?.message || error + ' :/'}</Typography>)
    }

    if (isSuccess) {
        const { count, trips } = data
        content = (
            <TripsList
                data={trips}
                next={() => dispatch(setFilteredPage({ page: page + 1, filters }))}
                hasMore={trips.length < count} />
        )
    }


    return (
        <Grid container pt={5}>
            <Grid
                item
                x={12}
                md={3.5}
                align='center'
            >
                <Fitlers />
            </Grid>
            <Grid
                item
                xs={11}
                md={5}
            >

                {content}

            </Grid>
            <CustomFab onClick={() => handleCreateTrip()} />
            <Outlet />
        </Grid>
    )
}

export default Home