import { Grid, Paper } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';


export default function TripCardSkeleton() {
    return (
        <Paper elevation={1} style={{ background: '#fff' }} sx={{ borderRadius: 3, margin: 2 }}>
            <Grid container alignItems="center" p={2}>
                <Grid container pb={2}>
                    <Grid item xs={1} display='flex' alignItems='center' flexWrap='wrap'>
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    </Grid>
                    <Grid item>
                        <Skeleton animation="wave" height={10} width="30%" style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="20%" />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Skeleton animation="wave" height={10} width="20%" />
                </Grid>
                <Grid item xs={12}>
                    {Array(8).fill().map((_, key) => <Skeleton key={key} animation="wave" height={10} />)}
                </Grid>
            </Grid>
        </Paper>
    )
}
