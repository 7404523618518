import { useState, useEffect } from 'react'
import { Grid, Divider, } from '@mui/material';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';

import CATEGORIES from '../../config/categories'

import { setFilteredPage } from '../../features/trips/pageSlice'
import { useDispatch } from "react-redux"

export default function Fitlers() {

    const [selectedFilters, setSelectedFilters] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setFilteredPage({ page: 0, filters: selectedFilters }))
    }, [selectedFilters]);

    const handleClick = (category) => {
        isSelected(category) ? handleDelete(category) : setSelectedFilters([...selectedFilters, CATEGORIES[category]])
    }

    const handleDelete = (category) => {
        setSelectedFilters(() => selectedFilters.filter((label) => label !== CATEGORIES[category]));
    };

    const isSelected = (category) => {
        return selectedFilters.includes(CATEGORIES[category])
    }

    const resetButton = (
        !!selectedFilters?.length &&
        <Grid item xs={12} style={{ textAlign: 'start' }}>
            <Divider sx={{ width: 70, mb: 1 }} />
            <Chip
                variant='outlined'
                size='small'
                label='Reset'
                onClick={() => setSelectedFilters([])}
            />
        </Grid>
    )

    return (
        <Grid container>
            <Grid item xs={0} md={4}> </Grid>
            <Grid item xs={12} md={8} pl={3} pr={3}>
                <Grid container spacing={1}>
                    {Object.keys(CATEGORIES).map((category, key) => (
                        <Grid key={key} item xs={3} md={12} style={{ textAlign: 'start' }}>
                            <Chip
                                variant={isSelected(category) ? 'filled' : 'outlined'}
                                size='small'
                                label={category}
                                onClick={() => handleClick(category)}
                                onDelete={isSelected(category) ? () => handleDelete(category) : null}
                                deleteIcon={<DeleteIcon />}
                            />
                        </Grid>
                    ))}
                    {resetButton}
                </Grid>
            </Grid>
        </Grid>
    )

}
