import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, DialogContent, DialogTitle, IconButton, Box, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    overflowY: 'auto',
    maxHeight: '80vh',
    maxWidth: '70vh',
    boxShadow: 20,
    p: '2vh',
    borderRadius: 3,
};

export function CustomModal({ Content, title, customStyle }) {
    const [open, setOpen] = React.useState(false);
    const closeModal = () => setOpen(false);
    const navigate = useNavigate();
    const handleClose = () => {
        closeModal()
        navigate(-1)
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Modal
            open={true}
            onClose={handleClose}
            disableRestoreFocus
            aria-labelledby="modal-modal-title"
            fullscreen={fullScreen}
            aria-describedby="modal-modal-description"
        >

            <Box sx={{ ...style, ...customStyle }}>

                <DialogTitle variant='h6' sx={{ display: 'flex', alignItems: 'center' }}>
                    {title}
                    <IconButton sx={{ ml: 'auto' }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {Content}
                </DialogContent>

            </Box>
        </Modal>
    )
}
