import { useState } from "react";
import { Typography, Box } from '@mui/material';


export const SmartTypography = ({ text, length = 20 }) => {
    const [showLess, setShowLess] = useState(true);

    const textWithLineBreaks = text.split('\n').map(function (item, key) {
        return (
            <span key={key}>
                {item}
                <br />
            </span>
        )
    })

    let content

    if (text.length < length) {
        content = <Typography>{textWithLineBreaks}</Typography>
    } else {
        content = (
            <Box>
                <Typography >{showLess ? `${text.slice(0, length)}...` : textWithLineBreaks}</Typography>
                <Typography
                    style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => setShowLess(!showLess)}>
                    {showLess ? 'Read More' : 'Read Less'}
                </Typography>
            </Box>
        )
    }

    return content
};