import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import { useNavigate } from "react-router-dom"

import { generateAwsS3Url } from '../config/utils'


export function ImageListCustom({ imageList, cols, disableFullImgDisplay }) {

  //disableFullImgDisplay if it's true that means that the component being used by the create post page.

  const navigate = useNavigate()

  const handleImgClicked = (e) => {
    const key = e.target.alt
    !disableFullImgDisplay && navigate(`img/${key}`)
  }

  return (
    <ImageList cols={cols} >
      {
        imageList.map((image) => (
          <ImageListItem key={image.key}>
            <img
              srcSet={disableFullImgDisplay && image.url}
              src={generateAwsS3Url(!disableFullImgDisplay ? ('trip_images/thumbnail_' + image.key) : (image.url))}
              alt={image.key}
              id={image.key}
              loading="lazy"
              style={{ cursor: 'pointer' }}
              onClick={handleImgClicked}
            />
          </ImageListItem>
        ))
      }
    </ImageList>
  )
}
