import { Box, CircularProgress, Typography, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';

import TripCard from '../features/trips/TripCard'
import CustomMenu from '../components/CustomMenu'

import InfiniteScroll from 'react-infinite-scroll-component';

import { useNavigate } from 'react-router-dom'

import { selectCurrentUserId } from '../features/auth/authSlice'
import { useSelector } from "react-redux"


export default function TripsList({ data, next, hasMore }) {

    const navigate = useNavigate()
    const currentUserId = useSelector(selectCurrentUserId)

    return (
        <InfiniteScroll
            dataLength={data.length}
            next={next}
            style={{ overflow: 'hidden' }} //Bug fix for scrollbar that shows when scrolling fast.
            hasMore={hasMore}
            loader={
                <Box
                    display="flex"
                    justifyContent="center">
                    <CircularProgress />
                </Box>
            }
            endMessage={
                <Box pt={8}>
                    <Divider variant="middle" />
                    <Typography p={3} sx={{ textAlign: 'center' }}>No more trips to display!</Typography>
                </Box>
            }
        >
            {data.map((trip, key) => (
                <TripCard
                    key={trip._id + key}
                    trip={trip}
                    options={
                        <CustomMenu menuOptions={
                            [
                                (currentUserId === trip.userId && { //Show edit btn only if current user is the creator of the trip
                                    title: 'Edit Trip',
                                    key: 'edit',
                                    onClick: () => navigate(`/trip/${trip._id}`),
                                    icon: <EditIcon />
                                }),
                                {
                                    title: 'Share Trip',
                                    key: 'share',
                                    onClick: () => { navigator.clipboard.writeText(window.location.hostname + `/trip/${trip._id}`) },
                                    icon: <SendIcon />
                                },
                            ]
                        } />
                    }
                />
            ))}
        </InfiniteScroll>
    )
}
