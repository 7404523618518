import { Grid, Box, Button, Typography, Divider, IconButton, Avatar, Stack } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { useSelector, useDispatch } from "react-redux"
import { useGetUserTripsQuery, useGetUserProfileQuery } from "./userApiSlice"
import { selectCurrentUserId } from '../auth/authSlice'
import { selectPage, setPage } from './userPageSlice'

import TripsList from '../../components/TripsList'
import TripCardSkeleton from '../trips/TripCardSkeleton'
import ProfileDataSkeleton from './ProfileDataSkeleton'

import { useParams, Outlet } from 'react-router-dom';


const Profile = () => {

    let { id: userId } = useParams()
    userId = userId.split('/')[0]

    const page = useSelector(selectPage)
    const currentUserId = useSelector(selectCurrentUserId)
    const isAccountOwner = currentUserId === userId
    const dispatch = useDispatch()

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUserTripsQuery({ page, userId }, {
        pollingInterval: 60000 * 3, // Automatically refresh the trips on home page every 3 minutes.
        refetchOnFocus: false,
        refetchOnMountOrArgChange: false
    })

    const {
        data: profileData,
        isLoading: isProfileLoading,
        isSuccess: isProfileSuccess,
        isError: isProfileError,
        error: profileError
    } = useGetUserProfileQuery({ userId }, {
        pollingInterval: 60000 * 3, // Automatically refresh the trips on home page every 3 minutes.
        refetchOnFocus: false,
        refetchOnMountOrArgChange: false
    })

    let tripsContent

    if (isLoading) {
        tripsContent = (
            <Grid item>
                {Array(8).fill().map((_, key) => <TripCardSkeleton key={key} />)}
            </Grid>
        )
    }

    if (isError) {
        console.log('error', error)
        tripsContent = (<Typography p={3}>{error.data?.message || error + ' :/'}</Typography>)
    }

    if (isSuccess) {
        const { count, trips } = data
        tripsContent = (
            <TripsList
                data={trips}
                next={() => dispatch(setPage({ page: page + 1 }))}
                hasMore={trips.length < count} />
        )
    }

    let profileContent

    if (isProfileSuccess) {
        profileContent = (
            <Grid container direction='row' justifyContent='space-between' alignItems='center' mb={3}>
                <Stack direction='row' alignItems='center'>
                    <IconButton onClick={() => { }} sx={{ p: 0, mr: '2vh' }}>
                        <Avatar sx={{ width: '12vh', height: '12vh' }} alt="person" src={profileData?.profileImage} />
                    </IconButton>
                    <Typography variant='h6' align='center'>{profileData?.username}</Typography>
                </Stack>

                {isAccountOwner && <Button style={{ height: '5vh' }} variant='outlined' startIcon={<ModeEditIcon />}>Edit Profile(soon!)</Button>}
            </Grid>
        )
    }

    if (isProfileLoading) {
        profileContent = (
            <ProfileDataSkeleton />
        )
    }

    if (isProfileError) {
        tripsContent = (<Typography p={3}>{profileError.data?.message || profileError + ' :/'}</Typography>)
    }

    return (
        <Grid container>
            <Box
                sx={{ width: '100%', height: '100%' }}
                display="flex"
                justifyContent="center"
            >
                <Grid
                    item
                    xs={11}
                    md={5}
                    pt={5}
                >
                    <Box>
                        {profileContent}
                        <Divider variant='fullWidth' />
                        {tripsContent}
                    </Box>
                </Grid>
            </Box>
            <Outlet />
        </Grid>
    )
}

export default Profile
